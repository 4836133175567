.home-section {
    display: flex;        
    flex-direction: column;
    justify-content: center;
    /*height: 90vh;*/
    margin-top: 30%;
    margin-bottom: 40%;
}

.HomeHeader1{
    font-weight: bolder;
    font-size: 4rem;
    /*margin-bottom: 0;*/
}
.HomeHeader2{
    font-weight: bold;
    font-size: medium;
    /*margin-bottom: 1px;*/
}

@media (max-width: 768px) {
    .home-section {
        height: 30vh;
    }
    
}