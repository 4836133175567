@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/
body, html, #root {
  /*margin: 0;*/
  /*height: 100%;*/
  /*overflow: auto;*/
  
  /*direction: ltr;*/
  /*color: black;*/
  font-family: 'Poppins', sans-serif;
}

html{
  scroll-behavior: smooth;
}

@media (max-width: 768px) {
  body, html, #root {
    overflow-x: hidden;
  }
}
