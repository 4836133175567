.AboutMe{
    text-align: left;
    /*height: 60vh;*/
    position: relative;
    /*margin-bottom: 30%;*/
    /*margin-top: 30%;*/
    /*margin-bottom: 100%;*/
}

.home-header{
    font-size: 2.5rem;
    min-font-size: 20px;
    font-weight: bold;
}

.tech-header {
    font-weight: bold;
}

.skills{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 0.6rem;
    margin-bottom: 3rem;
    position: relative;
}

.skills img {
    width: auto;
    height: 2rem;
    margin: 0 0.6rem;
    display: inline-block;
    object-fit: contain;
    transition: transform 0.3s; /* Smooth transition for scaling */
    position: relative;
    
}

.skills img:hover {
    transform: scale(1.2);
    /*transition: transform 0.3s;*/
}





.cv-button {
    display: inline-block;
    text-align: center;
    margin: 20rem auto; /* Centers the button horizontally */
    padding: 10rem 20rem;
    /*transition: transform 0.3s;*/
    position: relative; /* This ensures it behaves well within the flex container */
    left: 50%; /* Position it halfway across the container */
    transform: translateX(-50%); /* Shift it back to center */
    border-radius: 50%;
}

.cv-button:hover {
    /*transform: scale(1.1);*/
    transform: translateX(-50%) scale(1.1);
}

@media (max-width: 768px) {
    
    .AboutMe {
        /*margin-bottom: 90%;*/
    }

    .skills img {
        width: auto;
        height: 1.5rem;
        margin: 0.2rem 0.6rem 0.4rem;
        display: inline-block;
        object-fit: contain;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    
    
}

