.Footer {
    position: fixed;
    height: 100%;
    width: 40%;
    padding: 20px;
    display: flex;
    
    top: 0;
    right: 0;
    align-items: center;
    background-color: black;
    
}



.footer-links{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    /*margin-right: 10vw;*/
    /*margin-left: auto;*/

}


.footer-profile{
    width:50%;
    height:auto;
    /*width: 8vw; !* Make sure width and height are the same *!*/
    /*height: 8vw;*/
    margin-right:10%;
    margin-left:8vw;
    border-radius: 50%;
    /*object-fit: cover;*/
}

.footer-links a {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 10%;
    /*margin: 0;*/
    margin-right: 10%;
}
.footer-link-logo {
    width: 6vw;
    height: 3vw;
    margin-top: 15%;
    /*margin-right: 10%;*/
    border-radius: 50%;
    display: block;
    pointer-events: none;
}

.footer-link-logo img {
    pointer-events: auto;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .Footer {
        position: static;
        height: auto;
        width: 100%;
        /*order: 1;*/
    }

    /*.footer-profile{*/
    /*    width:50%;*/
    /*    height:auto;*/
    /*    margin-right:10%;*/
    /*    margin-left:8vw;*/
    /*    border-radius: 50%;*/
    /*    object-fit: cover;*/
    /*}*/

    .footer-link-logo {
        width: 8vw;
        height: 6vw;
        margin-top: 15%;
        /*margin-right: 10%;*/
        border-radius: 50%;
        display: block;
        pointer-events: none;
    }
}

