.Project {
    margin-top: 30%;
/*    text-align: left;*/
/*    height: 60vh;*/
/*    position: relative;*/
/*    margin-bottom: 10rem;*/

}

/*.Project .project-section {*/
/*    margin-top: 5%;*/
/*}*/

.project-header-main{
    font-size: 2.5rem;
    font-weight: bolder;
}

.project-header-sub {
    font-size: 1.5rem;
    font-weight: bolder;
    /*margin-top: 1.5rem;*/
}

.project-one-box {
    margin-bottom: 3rem;
}


.linkLogo {
    margin-top: 1.5rem;
    /*margin-bottom: 2rem;*/
}


/*.App-logo {*/
/*    margin-right: 10px;*/
/*}*/

@media (max-width: 768px) {

    .App-logo {
        margin-top: 1rem;
    }
}

