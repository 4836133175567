.Contact {
    /*text-align: left;*/
    /*height: 60vh;*/
    position: relative;
    margin-top: 30%;
    margin-bottom: 8rem;
}

.contact-main-header {
    font-size: 2.5rem;
    min-font-size: 20px;
    font-weight: bold;
}

.contact-sub-header {
    margin-bottom: 20px;
}

.TextField {
    margin-bottom: 15px !important;
}