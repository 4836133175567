.navbar {
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    /*left: 0;*/
    right: 45%;
    background-color: white; /* Set background color */
    z-index: 1000; /* Ensure it's on top */
    display: flex;
    flex-direction: row; /* Horizontal layout */
    padding: 10px;
    /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); !* Optional shadow *!*/
}

.navbar a {
    color: black; /* Default text color */
    margin: 0 15px; /* Space between links */
    text-decoration: none; /* Remove underline */
}

.navbar a:hover {
    color: black; /* Change color on hover */
    font-weight: bolder;
}

.navbar .active {
    font-weight: bold; /* Bold text for active section */
}

.menu-icon {
    display: none; /* Hide menu icon by default */
}

/* Media query for mobile view */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column; /* Stack links vertically */
        display: none; /* Hide navbar initially */
    }

    .navbar.hidden {
        display: none; /* Keep hidden when not toggled */
    }

    .navbar .menu-icon {
        display: block; /* Show hamburger icon */
        cursor: pointer; /* Show pointer on hover */
    }
}
