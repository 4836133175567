.reveal {
    opacity: 0;
    transform: translateY(20px); /* Move down slightly */
    transition: opacity 0.7s ease, transform 0.7s ease;
}

.reveal.visible {
    opacity: 1;
    transform: translateY(0); /* Reset position */
}
