.Layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.layout-navbar {
    position:fixed;
    background-color: white;
    z-index: 100;
    top: 0;
}

.content-container {
    flex: 1;
    /*margin-bottom: 30vh; !* Adjust margin as needed *!*/
    padding-left: 1.5%;
    margin-right: 50%;
}

section {
    margin: 2vh 0; /* Adding vertical space between all sections */
    padding: 0 3%; /* Padding inside the sections */
}

/* Make sure each section has consistent spacing */
#home, #about, #projects, #contact {
    margin-bottom: 5vh;
    padding-top: 5vh;
    /*padding-bottom: 5vh;*/
}




#container-footer {
    text-align: center;
}


@media (max-width: 768px) {
    
    .Layout {
        flex-direction: column;
    }
    
    .content-container {
        flex: 1;
        /*margin-bottom: 30vh; !* Adjust margin as needed *!*/
        padding-left: 3%;
        margin-right: 0;
    }
    
    .layout-footer {
        order: 0;
    }

    #home {
        order: 2; /* Home section appears 2nd */
    }

    #about {
        order: 3; /* About section appears 3rd */
    }

    #projects {
        order: 4; /* Projects section appears 4th */
    }

    #contact {
        order: 5;
    }

    section {
        margin: 3vh 0; /* Adjust margins for smaller screens */
    }
}


